import moment from 'moment-timezone'
import {
  handleError,
  handleGetResponse,
  handlePostResponse,
} from '../utils/handleResponse'
import Token from '../utils/Token'

import * as types from '../constants/ActionTypes'

export const fetchIscsiSettings = () => (dispatch) => (
  fetch('/edge/api/system/storage/iscsi/settings', {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.ISCSI_SETTINGS,
      settings: json,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const updateIscsiSettings = (values) => (dispatch) => (
  fetch('/edge/api/system/storage/iscsi/settings', {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
    body: JSON.stringify({
      ...values,
    }),
  })
    .then((resp) => handlePostResponse(resp, fetchIscsiSettings))
    .catch((e) => handleError(e, dispatch))
)

export const loginIscsiTarget = (values) => (dispatch) => (
  fetch('/edge/api/system/storage/iscsi/target/login', {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
    body: JSON.stringify({
      ...values,
    }),
  })
    .then((resp) => handlePostResponse(resp))
    .catch((e) => handleError(e, dispatch))
)

export const logoutIscsiTarget = (iqn) => (dispatch) => (
  fetch('/edge/api/system/storage/iscsi/target/logout', {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
    body: JSON.stringify({
      iqn,
    }),
  })
    .then((resp) => handlePostResponse(resp))
    .catch((e) => handleError(e, dispatch))
)

export const mountIscsiDisks = (remove) => (dispatch) => (
  fetch(`/edge/api/system/storage/iscsi/target/${remove ? 'unmount' : 'mount'}`, {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
    body: JSON.stringify({}),
  })
    .then((resp) => handlePostResponse(resp))
    .catch((e) => handleError(e, dispatch))
)

export const monitorIscsiDisks = (disk, unmonitor) => (dispatch) => (
  fetch(`/edge/api/system/storage/iscsi/target/${unmonitor ? 'unmonitor' : 'monitor'}`, {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
    body: JSON.stringify({
      diskId: disk,
    }),
  })
    .then((resp) => handlePostResponse(resp))
    .catch((e) => handleError(e, dispatch))
)

export const verifyIscsiDisk = (disk, iqn) => (dispatch) => (
  fetch('/edge/api/system/storage/iscsi/target/verify', {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
    body: JSON.stringify({
      diskId: disk,
      iqn,
    }),
  })
    .then((resp) => handlePostResponse(resp))
    .catch((e) => handleError(e, dispatch))
)

export const fetchIscsiTarget = (iqn, all) => (dispatch) => (
  fetch(`/edge/api/system/storage/iscsi/target${all ? '' : `?iqn=${iqn}`}`, {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.ISCSI_TARGETS,
      targets: json,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const discoverIscsiTargets = (values) => (dispatch) => (
  fetch('/edge/api/system/storage/iscsi/target/discovery', {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
    body: JSON.stringify({
      ...values,
    }),
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.ISCSI_DISCOVERED_TARGETS,
      targets: json,
    }))
    .catch((e) => {
      handleError(e, dispatch, true)
    })
)

export const fetchIscsiDisks = () => (dispatch) => (
  fetch('/edge/api/system/storage/iscsi/storageconfig', {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.ISCSI_DISKS,
      disks: json,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const fetchPhysicalDisks = () => (dispatch) => (
  fetch('/edge/api/system/storage/physical', {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.STORAGE_PHYSICAL_DISKS,
      storage: json,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const fetchVirtualDisks = () => (dispatch) => (
  fetch('/edge/api/system/storage/virtual', {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.STORAGE_VIRTUAL_DISKS,
      storage: json,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const checkVirtualDiskConsistency = (disk) => (dispatch) => (
  fetch(`/edge/api/system/storage/virtual/${disk}/check-consistency`, {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handlePostResponse(resp, dispatch, fetchVirtualDisks))
    .catch((e) => handleError(e, dispatch))
)

export const stopVirtualDiskConsistencyCheck = (disk) => (dispatch) => (
  fetch(`/edge/api/system/storage/virtual/${disk}/check-consistency?flag=cancel`, {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handlePostResponse(resp, dispatch, fetchVirtualDisks))
    .catch((e) => handleError(e, dispatch))
)

export const fetchInfo = () => (dispatch) => (
  fetch('/edge/api/system/info')
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.SYSTEM_INFO,
      info: json,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const fetchPackages = () => (dispatch) => (
  fetch('/edge/api/system/firmware/versions', {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.SYSTEM_PACKAGES,
      packages: json.packages,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const fetchServices = () => (dispatch) => (
  fetch('/edge/api/system/services', {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.SYSTEM_SERVICES,
      services: json,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const fetchSsh = () => (dispatch) => (
  fetch('/edge/api/system/debug/ssh', {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.SYSTEM_SSH,
      ssh: json,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const fetchTime = () => (dispatch) => (
  fetch('/edge/api/system/time', {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.SYSTEM_TIME,
      time: { ...json, current: moment(json.current).tz(json.timezone) },
    }))
    .catch((e) => handleError(e, dispatch))
)

export const fetchTimezones = () => (dispatch) => (
  fetch('/edge/api/system/timezones', {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.SYSTEM_TIMEZONES,
      timezones: json.timezones,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const fetchAccelerators = () => (dispatch) => (
  fetch('/edge/api/system/accelerators', {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.SYSTEM_ACCELERATORS,
      accelerators: json,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const reboot = () => (dispatch) => (
  fetch('/edge/api/system/reboot', {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handlePostResponse(resp))
    .catch((e) => handleError(e, dispatch))
)

export const selectLanguage = (lang) => (dispatch) => {
  window.localStorage.setItem('system.language', lang)
  window.location.reload()

  return dispatch({
    type: types.SYSTEM_LANGUAGE,
    language: lang,
  })
}

export const servicesToggle = (running) => (dispatch) => {
  dispatch({
    type: types.SYSTEM_SERVICES_BUSY,
    busy: true,
  })

  return fetch('/edge/api/system/services', {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
    body: JSON.stringify({ all: running }),
  })
    .then((resp) => handlePostResponse(resp, dispatch, fetchServices))
    .then(() => dispatch({
      type: types.SYSTEM_SERVICES_BUSY,
      busy: false,
    }))
    .catch((e) => {
      dispatch({
        type: types.SYSTEM_SERVICES_BUSY,
        busy: false,
      })
      return handleError(e, dispatch)
    })
}

export const updateSsh = (enabled) => (dispatch) => (
  fetch('/edge/api/system/debug/ssh', {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
    body: JSON.stringify({ enabled }),
  })
    .then((resp) => handlePostResponse(resp, dispatch, fetchSsh))
    .catch((e) => handleError(e, dispatch))
)

export const updateTime = (values) => (dispatch) => (
  fetch('/edge/api/system/time', {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
    body: JSON.stringify(values),
  })
    .then((resp) => handlePostResponse(resp, dispatch, fetchTime))
    .catch((e) => handleError(e, dispatch))
)

export const upgrade = (upgrading) => (dispatch) => {
  if (upgrading) {
    // system upgrade starts
    return dispatch({
      type: types.SYSTEM_UPGRADE,
      upgrading,
    })
  }

  // system upgrade finishes
  // reload the page to get new ui
  return window.location.reload(true)
}

export const fetchCertificates = () => (dispatch) => (
  fetch('/edge/api/system/certs', {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.CERTIFICATES,
      certs: json,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const fetchCertDetails = (type, name) => (dispatch) => (
  fetch(`/edge/api/system/certs?type=${type}&name=${name}`, {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.CERTIFICATE_DETAILS,
      certDetails: json,
    }))
    .catch((e) => handleError(e, dispatch))
)

export const deleteCertificate = (type, name) => (dispatch) => (
  fetch(`/edge/api/system/certs?type=${type}&name=${name}`, {
    method: 'DELETE',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handlePostResponse(resp, dispatch, fetchCertificates))
    .catch((e) => handleError(e, dispatch))
)

export const createCertificate = (type, name, data) => (dispatch) => (
  fetch(`/edge/api/system/certs/upload?type=${type}&name=${name}`, {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
    body: data,
  })
    .then((resp) => handlePostResponse(resp, dispatch, fetchCertificates))
    .catch((e) => handleError(e, dispatch))
)

export const updateCertificates = (update) => (dispatch) => (
  fetch('/edge/api/system/certs', {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
    body: JSON.stringify(update),
  })
    .then((resp) => handlePostResponse(resp, dispatch, fetchCertificates))
    .catch((e) => handleError(e, dispatch))
)

export const fetchDefaultDisableNewCerts = () => (dispatch) => (
  fetch('/edge/api/system/certs/disable-new', {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((result) => dispatch({
      type: types.CERTIFICATES_DEFAULT_DISABLE,
      defaultDisableNewCerts: result['disable-new'],
    }))
    .catch((e) => handleError(e, dispatch))
)

export const updateDefaultDisableNewCerts = (value) => (dispatch) => (
  fetch(`/edge/api/system/certs/disable-new?state=${value.toString()}`, {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handlePostResponse(resp, dispatch, fetchDefaultDisableNewCerts))
    .catch((e) => handleError(e, dispatch))
)

export const setDiskOffline = (diskId) => (dispatch) => (
  fetch(`/edge/api/system/storage/physical/${diskId}/offline`, {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handlePostResponse(resp, dispatch, fetchVirtualDisks, fetchPhysicalDisks))
    .catch((e) => handleError(e, dispatch))
)

export const setDiskOnline = (diskId) => (dispatch) => (
  fetch(`/edge/api/system/storage/physical/${diskId}/online`, {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handlePostResponse(resp, dispatch, fetchVirtualDisks, fetchPhysicalDisks))
    .catch((e) => handleError(e, dispatch))
)

export const setDiskBusy = (diskId) => (dispatch) => (
  dispatch({
    type: types.STORAGE_DISK_BUSY,
    disk: diskId,
  })
)

export const setDiskIdle = (diskId) => (dispatch) => (
  dispatch({
    type: types.STORAGE_DISK_IDLE,
    disk: diskId,
  })
)

export const rebuildDisk = (diskId) => (dispatch) => (
  fetch(`/edge/api/system/storage/physical/${diskId}/rebuild`, {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handlePostResponse(resp, dispatch, fetchVirtualDisks, fetchPhysicalDisks))
    .catch((e) => handleError(e, dispatch))
)

export const cancelDiskRebuild = (diskId) => (dispatch) => (
  fetch(`/edge/api/system/storage/physical/${diskId}/rebuild?flag=cancel`, {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handlePostResponse(resp, dispatch, fetchVirtualDisks, fetchPhysicalDisks))
    .catch((e) => handleError(e, dispatch))
)

export const identifyDisk = (diskId) => async (dispatch) => {
  const resp = await fetch(`/edge/api/system/storage/physical/${diskId}/identify`, {
    method: 'POST',
    headers: { 'X-Jwt-Token': Token.get() },
  })

  handlePostResponse(resp, dispatch)
}

export const stopIdentifyDisk = (diskId) => async (dispatch) => {
  const resp = await fetch(`/edge/api/system/storage/physical/${diskId}/identify?flag=off`, {
    method: 'POST',
    headers: { 'X-Jwt-Token': Token.get() },
  })

  handlePostResponse(resp, dispatch)
}

export const fetchDockerNetwork = () => (dispatch) => (
  fetch('/edge/api/system/docker/network', {
    headers: {
      'X-Jwt-Token': Token.get(),
    },
  })
    .then((resp) => handleGetResponse(resp))
    .then((json) => dispatch({
      type: types.SYSTEM_DOCKER_NETWORK,
      docker: {
        network: json,
      },
    }))
    .catch((e) => handleError(e, dispatch))
)

export const updateDockerNetwork = (data) => (dispatch) => (
  fetch('/edge/api/system/docker/network', {
    method: 'POST',
    headers: {
      'X-Jwt-Token': Token.get(),
    },
    body: JSON.stringify(data),
  })
    .then((resp) => handlePostResponse(resp, dispatch, fetchDockerNetwork))
    .catch((e) => handleError(e, dispatch))
)
