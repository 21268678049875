import { filter } from 'lodash'

import * as types from '../constants/ActionTypes'

const system = (state = {
  accelerators: [],
  certs: {},
  certDetails: {},
  defaultDisableNewCerts: false,
  info: {},
  language: window.localStorage.getItem('system.language') || 'en-US',
  packages: {},
  services: [],
  ssh: {},
  physicalDisks: [],
  virtualDisks: [],
  busyDisks: [],
  time: {},
  timezones: [],
  servicesBusy: false,
  upgrading: false,
  docker: {
    network: {},
  },
  iscsi: {
    settings: {},
    targets: [],
    disks: [],
  },
}, action) => {
  switch (action.type) {
    case types.CERTIFICATES:
      return { ...state, certs: action.certs }
    case types.CERTIFICATE_DETAILS:
      return { ...state, certDetails: action.certDetails }
    case types.CERTIFICATES_DEFAULT_DISABLE:
      return { ...state, defaultDisableNewCerts: action.defaultDisableNewCerts }
    case types.SYSTEM_ACCELERATORS:
      return { ...state, accelerators: action.accelerators }
    case types.STORAGE_PHYSICAL_DISKS:
      return { ...state, physicalDisks: action.storage }
    case types.STORAGE_VIRTUAL_DISKS:
      return { ...state, virtualDisks: action.storage }
    case types.STORAGE_DISK_BUSY:
      return { ...state, busyDisks: [...state.busyDisks, action.disk] }
    case types.STORAGE_DISK_IDLE:
      return { ...state, busyDisks: filter(state.busyDisks, (id) => id !== action.disk) }
    case types.STORAGE_DISK_IDENTIFY:
      return {
        ...state,
        physicalDisks: state.physicalDisks.map((disk) => {
          if (disk.id !== action.diskId) {
            return disk
          }

          return {
            ...disk,
            identifying: action.isBlinking,
          }
        }),
      }
    case types.SYSTEM_INFO:
      return { ...state, info: action.info }
    case types.SYSTEM_LANGUAGE:
      return { ...state, language: action.language }
    case types.SYSTEM_PACKAGES:
      return { ...state, packages: action.packages }
    case types.SYSTEM_SERVICES:
      return { ...state, services: action.services }
    case types.SYSTEM_SERVICES_BUSY:
      return { ...state, servicesBusy: action.busy }
    case types.SYSTEM_SSH:
      return { ...state, ssh: action.ssh }
    case types.SYSTEM_TIME:
      return { ...state, time: action.time }
    case types.SYSTEM_TIMEZONES:
      return { ...state, timezones: action.timezones }
    case types.SYSTEM_UPGRADE:
      return { ...state, upgrading: action.upgrading }
    case types.SYSTEM_DOCKER_NETWORK:
      return { ...state, docker: { ...state.docker, network: action.docker.network } }
    case types.ISCSI_SETTINGS:
      return { ...state, iscsi: { ...state.iscsi, settings: action.settings } }
    case types.ISCSI_TARGETS:
      // only update targets that have information fetched
      state.iscsi.targets.forEach((existingTarg) => {
        const idx = action.targets.findIndex((fetchedTarg) => {
          if (fetchedTarg?.iqn === undefined || existingTarg?.iqn === undefined) {
            return false
          }
          return fetchedTarg.iqn === existingTarg.iqn
        })
        if (idx === -1) {
          action.targets.splice(0, 0, existingTarg)
        }
      })
      return { ...state, iscsi: { ...state.iscsi, targets: action.targets } }
    case types.ISCSI_DISCOVERED_TARGETS:
      // only add discovered targets if they do not already exist in state
      state.iscsi.targets.forEach((existingTarg) => {
        const idx = action.targets.findIndex((discoveredTarg) => {
          if (discoveredTarg?.iqn === undefined || existingTarg?.iqn === undefined) {
            return false
          }
          return discoveredTarg.iqn === existingTarg.iqn
        })
        if (idx !== -1) {
          action.targets.splice(idx, 1, existingTarg)
        } else {
          action.targets.splice(0, 0, existingTarg)
        }
      })
      return { ...state, iscsi: { ...state.iscsi, targets: action.targets } }
    case types.ISCSI_DISKS:
      return { ...state, iscsi: { ...state.iscsi, disks: action.disks } }
    default:
      return state
  }
}

export default system
