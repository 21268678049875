// eslint-disable-next-line import/no-cycle
import { logout } from '../actions/user'

export const handleError = (error, dispatch, throwGet = false) => {
  if (error.status === 401) {
    return dispatch(logout())
  }

  if (error.method === 'GET') {
    // eslint-disable-next-line no-console
    console.warn(error.message)
    if (throwGet) {
      throw error
    }
  } else {
    if (error.message === 'Failed to fetch') {
      throw new Error('common.timeout')
    }
    throw error
  }

  return Promise.resolve()
}

// http get
export const handleGetResponse = (resp) => {
  if (resp.ok) {
    return resp.json()
  }

  const error = new Error(resp.statusText)
  error.method = 'GET'
  error.status = resp.status
  throw error
}

// http post, put, and delete
export const handleResponse = (method, resp, dispatch, ...actions) => {
  if (resp.ok) {
    if (dispatch && actions.length >= 1) {
      let ret = dispatch(actions[0]())
      for (let i = 1; i < actions.length; i += 1) {
        if (actions[i]) {
          ret = ret.then(() => dispatch(actions[i]()))
        }
      }
      return ret
    }
  } else {
    const error = new Error(resp.statusText)
    error.method = method
    error.status = resp.status

    if (resp.headers && resp.headers.get('content-type') === 'application/json') {
      return resp.json()
        .then((data) => {
          error.message = data.message
          throw error
        })
    }

    throw error
  }

  return Promise.resolve()
}

export const handlePostResponse = (...params) => (
  handleResponse('POST', ...params)
)

export const handlePutResponse = (...params) => (
  handleResponse('PUT', ...params)
)

export const handleDeleteResponse = (...params) => (
  handleResponse('DELETE', ...params)
)
